.container {
  position: relative;
  width: 310px;
  top: calc(var(--headerHeight) + var(--secondaryNavHeight));
  transition: all ease-in-out 0.3s;
  @apply sticky z-30 border-r border-defaultBorder;

  height: calc(100vh - var(--headerHeight) - var(--secondaryNavHeight, 75px));
  @media (max-width: 767px) {
    width: 100%;
    border: none;
    overflow: visible;
  }
}

.selectOption {
  width: 100%;
  align-items: flex-start;
  position: relative;

  &:not(:first-child) {
    margin-top: 8px;
  }

  &.margin {
    margin-top: 16px;
  }

  span {
    @media (max-width: 767px) {
      font-size: 16px;
    }

    &:not(:first-child) {
      width: calc(100% - 30px);
    }
  }

  .lblWaxAddress {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    cursor: pointer;

    &:hover {
      .btnCopy {
        display: flex;
      }
    }

    .btnCopy {
      display: none;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);

      @media (max-width: 768px) {
        display: flex;
      }
    }
  }
}
