.container {
  position: relative;
  transition: all 0.5s ease;
  min-height: 70px;

  &:first-child {
    .fadeAni {
      border: none;
    }
  }

  .triggerWrapper {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 36px;
    height: 36px;
    left: 20px;
    top: 16px;

    .trigger {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      width: 36px;
      height: 36px;
      border-radius: 8px;

      &.active {
        @apply bg-gray-700;
      }

      &:global(.enter) {
        transform: translateX(-76px);
      }

      &:global(.enter-active) {
        transform: none;
        transition: transform 300ms;
      }

      &:global(.exit) {
        transform: none;
      }

      &:global(.exit-active) {
        transform: translateX(-76px);
        transition: transform 300ms;
      }

      .headerIcon {
        margin: 0;
      }
    }
  }

  .fadeAni {
    &:global(.enter-active) {
      opacity: 1;
      transition: opacity 300ms linear;
    }

    &:global(.exit) {
      opacity: 1;
    }

    &:global(.exit-active) {
      opacity: 0;
      transition: opacity 300ms linear;
    }

    .header {
      position: relative;
      display: flex;
      align-items: center;
      padding: 24px 32px;
      cursor: pointer;

      &:hover {
        .expandIcon {
          opacity: 1;
        }
      }

      @media (max-width: 767px) {
        padding: 24px 0;
        font-size: 18px;
      }

      .headerIcon {
        margin-right: 12px;
      }

      .title {
        transition: all 0.5s ease;
      }

      .expandIcon {
        position: absolute;
        top: 50%;
        right: 32px;
        transform: translateY(-50%);
        opacity: 0;
        transition: all ease 0.5s;
        color: white;

        @media (max-width: 767px) {
          right: 0;
          opacity: 1;
        }

        &.collapse {
          transform: translateY(-50%) rotate(180deg);
        }
      }
    }

    .collapse {
      transition: all ease var(--inventory-filter-transition-duration);

      .content {
        padding: 0 32px 24px;

        @media (max-width: 767px) {
          padding: 0 0 24px;
        }
      }
    }
  }
}

.tooltipHeader {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  @apply text-white;

  .headerIcon {
    margin-right: 12px;
  }
}

.tooltipContent {
  margin-top: 16px;
}
