.container {
  position: absolute;
  right: -8px;
  top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 30px;
  height: 34px;
  border-radius: 0px 12px 12px 0px;
  transition: all 0.3s;
  color: white;
  z-index: 1;
  cursor: pointer;
  @apply bg-gray-700;

  &::before {
    content: '';
    position: absolute;
    top: -10px;
    right: -20px;
    bottom: -10px;
    left: 0;
    flex-shrink: 0;
  }

  &:hover {
    right: -30px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    @apply bg-blue;
  }
}
